<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">外部サイト用ウィジェットタグの管理</h1>
        <banner-hint>
          登録したウィジェットタグはホーム画面やキャストプロフィール画面にて表示できるようになります。<br />
          外部ポータルサイトの広告プランにてウィジェット設置の条件等がある場合にご利用ください。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- 新規追加ボタン -->
        <div class="mb-7">
          <v-btn
            depressed
            color="primary"
            @click="addWidget()"
          >ウィジェットを追加</v-btn>
        </div>

        <v-card v-if="!widgets.length" flat>
          <v-card-text>ウィジェットが登録されていません。</v-card-text>
        </v-card>

        <v-card
          class="mb-5 pt-7 pb-5 px-6"
          elevation="1"
          v-for="(widget, index) in widgets"
          :key="widget.widget_id"
        >
          <v-form :ref="'form-widget-' + widget.widget_id">
            <!-- ******************************************** -->
            <!-- ウィジェット名 -->
            <!-- ******************************************** -->
            <v-row>
              <v-col cols="12">
                <!-- 名前 -->
                <v-text-field
                  class="pt-2"
                  v-model="widget.widget_name"
                  label="ウィジェット名"
                  :rules="[valiRules.required, valiRules.max20]"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- ******************************************** -->
            <!-- タグ枠 -->
            <!-- ******************************************** -->
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="widget.html_tag"
                  filled
                  min-height="150"
                  counter="2000"
                  label="ウィジェットタグ"
                  :rules="[valiRules.required, valiRules.max2000]"
                ></v-textarea>
              </v-col>
              <!-- 背景色 -->
              <v-col class="py-0 d-flex justify-end">
                <v-select
                  v-model="widget.bg_color"
                  :items="bgColorList"
                  item-text="label"
                  item-value="value"
                  color="primary"
                  label="ウィジェット枠の背景色を選択"
                  :rules="[valiRules.required]"
                ></v-select>
              </v-col>
              <!-- ウィジェットディスプレイ -->
              <v-col cols="12"
                v-if="!widget.create"
              >
                <v-card
                  class="widget-display px-5"
                  :color="widget.bg_color"
                >
                  <div v-html="widget.html_tag"></div>
                </v-card>
              </v-col>
            </v-row>

            <!-- ボタン -->
            <v-row no-gutters class="mt-7 justify-end">
              <div>
                <v-btn
                  v-if="widget.create"
                  depressed
                  color="accent"
                  @click="createRow(widget)"
                >登録</v-btn>
                <v-btn
                  v-else
                  depressed
                  color="primary"
                  @click="updateRow(widget)"
                >更新</v-btn>
                <v-btn
                  class="ml-2"
                  text
                  color="primary"
                  @click="deleteRow(index)"
                >削除</v-btn>
              </div>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxWidgetId: 0,
      widgets: [],
      bgColorList: [
        {label: '透明', value: 'transparent'},
        {label: '白', value: 'white'},
        {label: '黒', value: 'black'},
      ],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      this.setBgColorList()

      await Promise.all([
        this.getSiteWidget(),
      ])

      this.loading = false
    },

    //新規追加
    addWidget() {
      if (this.widgets.length >= 10) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.widgets.unshift({
        widget_id: ++this.maxWidgetId,
        widget_name: '',
        html_tag: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    getSiteWidget() {
      return this.adminApi.getReqWithAuth('site-widget/').then( results => {
        if (!results || !results.length) return

        //id最大値の取得
        this.maxWidgetId = Math.max(...results.map( row => parseInt(row.widget_id) ))

        this.widgets = results
      })
    },

    setBgColorList() {
      return this.adminApi.getReqWithAuth('site-setting/').then( data => {
        if (!data) return

        this.bgColorList.push({label: 'メインカラー', value: data.primary_color})
        this.bgColorList.push({label: 'サブカラー', value: data.secondary_color})
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(widget) {
      if (!widget.widget_name || !widget.html_tag || !this.$refs['form-widget-' + widget.widget_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append('widget_name', widget.widget_name)
      formData.append('html_tag', widget.html_tag)
      formData.append('bg_color', widget.bg_color)

      this.adminApi.apiReqWithData('POST', 'create/site-widget/', formData).then( response => {
        widget.widget_id = response.widget_id
        widget.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(widget) {
      if (!widget.widget_name || !widget.html_tag || !this.$refs['form-widget-' + widget.widget_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/site-widget/' + widget.widget_id
      const formData = new FormData()
      formData.append('widget_name', widget.widget_name)
      formData.append('html_tag', widget.html_tag)
      formData.append('bg_color', widget.bg_color)

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.widgets[index].create) {
        this.widgets.splice(index, 1)
        return
      }

      const apiPath = 'delete/site-widget/' + this.widgets[index].widget_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.widgets.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  }
}
</script>

<style scoped>
.v-textarea .v-input__slot {
  overflow-y: scroll;
}
.widget-display {
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.widget-display::-webkit-scrollbar {
  display: none;
}
</style>
